import { clsx } from 'clsx/lite';
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ForwardedRef,
  type ReactElement,
  type ReactNode,
  cloneElement,
  forwardRef,
} from 'react';
import {
  type AriaDialogProps,
  type AriaModalOverlayProps,
  Overlay,
  useDialog,
  useModalOverlay,
  useOverlayTrigger,
} from 'react-aria';
import {
  type ButtonProps as RACButtonProps,
  Button as RACButton,
  DialogContext,
  Heading as RACHeading,
  ModalContext,
  useContextProps,
} from 'react-aria-components';
import {
  type OverlayTriggerProps,
  type OverlayTriggerState,
  useOverlayTriggerState,
} from 'react-stately';
import { isFunction } from 'remeda';

import { Cancel } from '../../icons/cancel.js';
import { buttonRecipe } from '../button/button.css.js';
import {
  closeButtonStyles,
  dialogHeadingStyles,
  dialogStyles,
  modalStyles,
  underlayStyles,
} from './dialog.css.js';

interface DialogModalProps extends AriaModalOverlayProps {
  state: OverlayTriggerState;
  children: ReactNode;
}
export const DialogModal = forwardRef(function DialogModal(
  { state, children, ...props }: DialogModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  [props, ref] = useContextProps(props, ref, ModalContext);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  return (
    <Overlay>
      <div {...underlayProps} className={underlayStyles}>
        <div {...modalProps} className={modalStyles} ref={ref}>
          {children}
        </div>
      </div>
    </Overlay>
  );
});

type DialogClose = (close: () => void) => ReactElement;

interface DialogTriggerProps extends OverlayTriggerProps {
  children: [ReactElement | ReactNode, DialogClose | ReactElement];
  isDismissable?: boolean;
  isOpen?: boolean;
}

export function DialogTrigger({ children, ...props }: DialogTriggerProps) {
  if (!Array.isArray(children) || children.length > 2) {
    throw new Error('DialogTrigger must have exactly 2 children');
  }

  // if a function is passed as the second child, it won't appear in toArray
  const [trigger, content] = children as [ReactElement, DialogClose];

  const { isDismissable } = props;
  const state = useOverlayTriggerState(props);
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
  );

  return (
    <>
      {trigger ?
        cloneElement(trigger, triggerProps)
      : <RACButton {...triggerProps}>Open Dialog</RACButton>}
      {state.isOpen ?
        <DialogModal {...props} state={state}>
          {isDismissable ?
            <CloseButton onPress={state.close} />
          : null}
          {cloneElement(
            isFunction(content) ? content(state.close) : content,
            overlayProps,
          )}
        </DialogModal>
      : null}
    </>
  );
}

export interface DialogProps extends AriaDialogProps {
  title?: string;
  children?: ReactNode;
}
export const Dialog = forwardRef(function Dialog(
  { title, children, ...props }: DialogProps,
  ref: ForwardedRef<HTMLElement>,
) {
  [props, ref] = useContextProps(props, ref, DialogContext);
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <section {...dialogProps} className={dialogStyles} ref={ref}>
      {title ?
        <DialogTitle {...titleProps}>{title}</DialogTitle>
      : null}
      {children}
    </section>
  );
});

type CloseButtonProps = RACButtonProps;
function CloseButton({ className, ...props }: CloseButtonProps) {
  const classNames = clsx(
    className,
    buttonRecipe({ color: 'white', kind: 'tertiary', size: 'icon' }),
    closeButtonStyles,
  );
  return (
    <RACButton {...props} className={classNames}>
      <Cancel size={24} />
    </RACButton>
  );
}

export const DialogTitle = forwardRef<
  ElementRef<typeof RACHeading>,
  ComponentPropsWithoutRef<typeof RACHeading>
>(function Heading({ className, ...props }, ref) {
  return (
    <RACHeading
      {...props}
      className={clsx(dialogHeadingStyles, className)}
      ref={ref}
      slot="title"
    />
  );
});
